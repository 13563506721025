<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <div class="pb-[24px] pt-[8px] flex justify-between gap-y-[1rem] gap-x-[3rem] flex-wrap w-full">
      <div class="flex gap-[1rem] flex-wrap grow md:max-w-[610px]">
        <div class="flex gap-[1rem] flex-wrap grow basis-[334px]">
          <BDropdown
            id="dropdown-1"
            class="filter"
            no-caret
            variant="primary"
            toggle-class="text-decoration-none !rounded-[8px] h-[43px] w-[43px] grid place-items-center bg-[#F95031] text-white !p-0"
          >
            <h5>Filter</h5>
            <template #button-content>
              <i class="k-filter-search text-[24px]" />
            </template>
            <BDropdown
              id="dropdown-2"
              class="w-100"
              toggle-class="text-left"
              variant="none"
              left
              no-caret
              dropright
            >
              <template #button-content>
                <span class="hover-salmon text-6 w-100">Status</span>
              </template>
              <BDropdownForm style="width: 200px">
                <BFormCheckbox
                  v-for="(status, index) in filterOptions"
                  :key="index"
                  v-model="filterSelected"
                  :name="`checkbox-${index}`"
                  class="text-6 mb-1"
                  :value="status.value"
                  @change="getListData()"
                >
                  {{ status.text }}
                </BFormCheckbox>
              </BDropdownForm>
            </BDropdown>
            <BDropdown
              id="dropdown-3"
              class="w-100"
              toggle-class="text-left"
              variant="none"
              left
              no-caret
              dropright
            >
              <template #button-content>
                <span class="hover-salmon text-6 w-100">Periode</span>
              </template>
              <MonthRangePicker
                :model.sync="periode"
                :loading="loading"
                :max-range="6"
              />
            </BDropdown>
            <h5
              class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
              @click="resetFilter()"
            >
              Reset filter
            </h5>
          </BDropdown>
          <div class="d-flex align-items-center rounded-[8px] h-[43.5px] overflow-hidden komsearch grow basis-[276px]">
            <i
              class="k-search-normal-1 ml-[12px] text-[24px] cursor-pointer"
              @click="$refs.searchInvoice.focus()"
            />
            <b-form-input
              id="search-invoice-history"
              ref="searchInvoice"
              v-model="keyword"
              type="search"
              placeholder="Cari Nama atau No Partner"
              debounce="500"
              class="border-0 shadow-none"
              size="lg"
              @input="handleSearch()"
            />
          </div>
        </div>
        <MultiSelector
          class="grow"
          name="history-select-talent"
          :model-value.sync="selectedTalentLeads"
          :model-search.sync="searchTalentLead"
          :options="talentLeads"
          :loading="multiSelectLoad"
          @onBlur="onSelectTalentLeadBlur"
        />
      </div>
      <BButton
        :disabled="!periode || loadDownload || loading"
        variant="primary"
        :class="{ 'k-document-download': !loadDownload }"
        class="!text-[20px] h-[43px] px-[34px] rounded-[8px] !grid place-items-center grow md:grow-0 disabled:!bg-[#C2C2C2] disabled:!border-[#C2C2C2] disabled:pointer-events-none disabled:!text-black"
        @click="onDownloadInvoice"
      >
        <template v-if="loadDownload">
          {{ percentDownload }}%
        </template>
      </BButton>
    </div>
    <div class="d-flex py-[20px] justify-end border-t">
      <span class="text-8 text--dark font-bold">Total Nominal: {{ IDR(totalNominal) }}</span>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 405px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
          :small="true"
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(nominal)="data">
            <div class="flex flex-col gap-2 items-center">
              <span>{{ IDR(data.item.nominal) }}</span>
              <span
                :class="data.item.is_early_payment ? 'status-green' : 'status-blue'"
                class="status rounded-full whitespace-nowrap"
              >{{ data.item.is_early_payment ? 'Pembayaran Awal' : 'Pembayaran Akhir' }}</span>
            </div>
          </template>
          <template #cell(partner_name)="data">
            <div class="d-flex flex-col">
              <span class="text-8 font-[600]">{{ data.item.partner_name }}</span>
              <span class="text-6">{{ data.item.no_partner }}</span>
            </div>
          </template>
          <template #cell(invoice_code)="data">
            <div class="flex flex-col [&>*]:bg-transparent [&>*]:capitalize [&>*]:whitespace-nowrap [&>*]:text-[14px] [&>*]:text-black [&>*]:bg-[#F4F4F4] [&>*]:py-[12px]">
              <span>{{ data.item.invoice_code || '-' }}</span>
              <span class="border-t border-[#E2E2E2]">{{ data.item.xendit_external_id || '-' }}</span>
            </div>
          </template>
          <template #cell(invoice_paid)="data">
            <BProgress
              height="24px"
              :max="data.item.detail_invoices.length"
              variant="success"
            >
              <BProgressBar
                :value="data.item.detail_invoices.filter((val) => val.invoice_is_paid).length"
                :label="`${data.item.detail_invoices.filter((val) => val.invoice_is_paid).length} / ${data.item.detail_invoices.length}`"
              />
            </BProgress>
          </template>
          <template #cell(published)="data">
            <span
              v-if="data.item.is_publish"
              class="k-tick-circle h-100 font-bold text-12"
              style="color: #34A770;"
            />
            <BButton
              v-else
              v-b-modal.confirmationPublish
              size="md"
              variant="outline-primary"
              @click="selectItem(data.item.id)"
            >
              Publish
            </BButton>
          </template>
          <template #cell(paid)="data">
            <span
              v-if="data.item.is_paid"
              class="k-tick-square h-100 text-12 font-weight-light"
              style="color: #34A770;"
            />
            <span
              v-else
              class="k-minus-cirlce h-100 text-12 font-weight-light"
              style="color: #FBA63C;"
            />
          </template>
          <template #cell(action)="data">
            <BButton
              class="btn-icon p-[1px]"
              size="sm"
              variant="primary"
              :to="{
                name: $route.meta.routeDetailHistory,
                params: { id: data.item.id }
              }"
              tag="router-link"
              style="padding: 4px"
            >
              <span
                class="k-search-normal-1 h-100 font-weight-light text-6 pt-[7px] align-middle"
              />
            </BButton>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ConfirmationPublish
      :loading-submit="loadingSubmit"
      @publish="publish"
    />
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { alertError, alertSuccess } from '@toast'
import { LABELMONTH } from '@/libs/filterDate'
import { tableHistory, filterOptions } from '../config'
import ConfirmationPublish from '../modal/ConfirmationPublish.vue'
import MultiSelector from '../components/MultiSelector.vue'
import MonthRangePicker from '../components/MonthRangePicker.vue'

export default {
  components: {
    ConfirmationPublish,
    MonthRangePicker,
    MultiSelector,
  },
  data() {
    return {
      filterOptions,
      periode: null,
      keyword: '',
      fields: tableHistory,
      loading: false,
      loadingSubmit: false,
      loadDownload: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      idItem: 0,
      filterSelected: [],
      totalNominal: 0,
      alertError,
      alertSuccess,
      monthlabel: LABELMONTH,
      IDR,
      searchTalentLead: '',
      selectedTalentLeads: [],
      talentLeads: [],
      multiSelectLoad: false,
      percentDownload: 0,
    }
  },
  computed: {
    status() {
      return this.filterSelected.join(',')
    },
    period() {
      if (!this.periode) return ''
      const startDate = `${this.periode.monthStart.year}-${this.periode.monthStart.month}`
      const endDate = `${this.periode.monthEnd.year}-${this.periode.monthEnd.month}`
      return `&date_start=${startDate}&date_end=${endDate}`
    },
    talentLeadsParam() {
      if (this.selectedTalentLeads.length === 0) return ''
      return `&${this.selectedTalentLeads.map(t => `talent_lead=${t}`).join('&')}`
    },
  },
  watch: {
    searchTalentLead() {
      this.getTalentLeads(false)
    },
    periode() {
      this.getListData()
    },
  },
  mounted() {
    this.getListData()
    this.getTalentLeads()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')

      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `type=history&offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&status=${this.status}${this.period}${this.talentLeadsParam}`
      const url = `v1/invoices?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data.data
          this.totalNominal = res.data.data.total_nominal
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `type=history&offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&status=${this.status}${this.period}${this.talentLeadsParam}`
        const url = `v1/invoices?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    resetFilter() {
      this.filterSelected = []
      this.periode = null

      this.getListData()
    },
    async publish() {
      this.loadingSubmit = true

      const url = `v1/invoices/${this.idItem}/publish`
      await komtimAxiosIns
        .put(url)
        .then(() => {
          const text = 'Berhasil publish invoice'
          this.alertSuccess(text)

          this.$bvModal.hide('confirmationPublish')
          this.getListData()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async getTalentLeads(load = true) {
      try {
        this.multiSelectLoad = load
        const { data: { data } } = await komtimAxiosIns.get(`v1/invoices/talent_lead?search=${this.searchTalentLead}`)
        this.talentLeads = data.map(d => ({ text: d.name, value: d.id.toString() }))
      } catch (error) {
        this.alertError(error)
      } finally {
        this.multiSelectLoad = false
      }
    },
    onSelectTalentLeadBlur(same) {
      if (!same) this.getListData()
    },
    async onDownloadInvoice() {
      try {
        this.loadDownload = true
        const params = `type=history&keyword=${encodeURIComponent(this.keyword)}&status=${this.status}${this.period}${this.talentLeadsParam}`

        const res = await komtimAxiosIns.get(`v1/invoices/download?${params}`, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            if (progressEvent.total) {
              this.percentDownload = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          },
        })

        const blob = new Blob([res.data], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        const startDate = `${this.periode.monthStart.month}-${this.periode.monthStart.year}`
        const endDate = `${this.periode.monthEnd.month}-${this.periode.monthEnd.year}`
        a.download = `invoice_history_${startDate}${startDate === endDate ? '' : `_${endDate}`}.pdf`
        a.target = '_blank'
        a.click()

        a.remove()
        URL.revokeObjectURL(url)
      } catch (error) {
        this.$toast_error({ message: 'Gagal mengunduh invoice' })
      } finally {
        this.loadDownload = false
        this.percentDownload = 0
      }
    },
  },
}
</script>

<style scoped lang="scss">
.progress {
  background: #E2E2E2;
}
.status {
  padding: 1px 10px;
  text-transform: capitalize;
  font-size: small;

  &-blue {
    background: #DFF3FF;
    color: #08A0F7;
  }

  &-green {
    background: #DCF3EB;
    color: #34A770;
  }
}
</style>

<style scoped>
#search-invoice-history {
  font-size: 14px;
}

.komsearch {
  border: 1px solid #E2E2E2;
}

.komsearch:has(#search-invoice-history:focus) {
  border-color: rgb(249, 80, 49);
}
</style>
