<template>
  <div class="w-[248px] flex flex-col gap-[4px] absolute -top-[2px] select-none">
    <div class="h-[40px] rounded-[8px] border-px border-[#E2E2E2]  py-[8px] px-[12px] bg-white shadow-[0_0_8px_0_#00000040] text-[#222222] flex items-center justify-between">
      <span
        class="text-[14px] font-medium tracking-[0.5px]"
        :class="{ 'text-[#c2c2c2]': !strModel }"
      >{{ strModel || placeholder }}</span>
      <button
        v-if="model"
        class="k-close-circle text-[19px] cursor-pointer hover:text-[#F95031] hover:disabled:text-black active:brightness-90 active:disabled:brightness-100 transition-colors duration-300 outline-none border-none"
        :disabled="loading"
        @click="clearModel"
      />
    </div>
    <div class="h-[245px] rounded-[8px] bg-[#F8F8F8] shadow-[0_0_8px_0_#00000040] pt-[25px] px-[12px] pb-[16px]">
      <div class="flex justify-between items-center mb-[14px]">
        <button
          aria-label="decrease year"
          class="k-arrow-left-2 border-none outline-none text-[19px] text-black cursor-pointer hover:text-[#F95031] active:brightness-90 transition-colors duration-300"
          @click="year = year - 1"
        />
        <span class="text-[14px] text-black">{{ year }}</span>
        <button
          aria-label="increase year"
          :disabled="year === new Date().getFullYear()"
          class="k-arrow-right-2 border-none outline-none text-[19px] text-black cursor-pointer hover:text-[#F95031] active:brightness-90 transition-colors duration-300 hover:disabled:text-black active:disabled:brightness-100 disabled:opacity-50"
          @click="year = year + 1"
        />
      </div>
      <div class="flex flex-wrap rounded-[6px] overflow-hidden">
        <div
          v-for="m, idx in LABELMONTH"
          :key="idx"
          class="w-[74px] h-[44px] bg-white group"
        >
          <button
            :disabled="disabledMonth(idx)"
            class="text-[14px] text-black h-full w-full grid place-items-center hover:text-[#F95031] active:brightness-90 transition-colors duration-300 outline-none border-none hover:disabled:text-black active:disabled:brightness-100 disabled:opacity-50"
            :class="[selectedStyle(idx), { 'hover:bg-[#FFECE9] hover:disabled:bg-white': hoverStyle(idx) }]"
            @mouseover="() => onHoverMonth(idx)"
            @click="() => onSelectMonth(idx)"
          >
            {{ m }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LABELMONTH } from '@/libs/filterDate'

export default {
  props: {
    model: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxRange: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Pilih Periode',
    },
  },
  data() {
    return {
      LABELMONTH,
      year: new Date().getFullYear(),
      selectedStart: null,
      selectedEnd: null,
      hoverEnd: null,
    }
  },
  computed: {
    strModel() {
      if (!this.model) return ''
      const startMonth = this.model.monthStart.month - 1
      const endMonth = this.model.monthEnd.month - 1
      const startYear = this.model.monthStart.year
      const endYear = this.model.monthEnd.year
      return `${LABELMONTH[startMonth]} ${startYear} - ${LABELMONTH[endMonth]} ${endYear}`
    },
  },
  mounted() {
    this.selectedStart = this.model ? this.model.monthStart : null
    this.selectedEnd = this.model ? this.model.monthEnd : null
  },
  methods: {
    onSelectMonth(idx) {
      if (!this.selectedStart) {
        this.selectedStart = { month: idx + 1, year: this.year }
        return
      }
      if (this.selectedEnd) {
        this.selectedEnd = null
        this.selectedStart = { month: idx + 1, year: this.year }
        return
      }
      this.selectedEnd = { month: idx + 1, year: this.year }
      if ((this.selectedEnd.year * 100) + this.selectedEnd.month < (this.selectedStart.year * 100) + this.selectedStart.month) {
        const currentStart = this.selectedStart
        this.selectedStart = this.selectedEnd
        this.selectedEnd = currentStart
      }
      this.$emit('update:model', { monthStart: this.selectedStart, monthEnd: this.selectedEnd })
    },
    onHoverMonth(idx) {
      if (this.selectedEnd) {
        this.hoverEnd = null
        return
      }
      this.hoverEnd = (this.year * 100) + (idx + 1)
    },
    disabledMonth(idx) {
      const baseDisableLogic = (idx > new Date().getMonth() && this.year >= new Date().getFullYear()) || this.loading
      if (this.maxRange && this.selectedStart && !this.selectedEnd) {
        let minimum = this.selectedStart.month - this.maxRange + 1
        let maximum = this.selectedStart.month + this.maxRange - 1
        let infimum = (this.selectedStart.year * 100) + minimum
        let suprimum = (this.selectedStart.year * 100) + maximum

        if (minimum < 0) {
          minimum = Math.abs(minimum) + 1
          const minRest = minimum % 12
          infimum = ((this.selectedStart.year - Math.floor(minimum / 12) - (minRest > 0 ? 1 : 0)) * 100) + (minRest > 0 ? (13 - minRest) : 1)
        }
        if (maximum > 12) {
          maximum -= 12
          const maxRest = maximum % 12
          suprimum = ((this.selectedStart.year + Math.ceil(maximum / 12)) * 100) + (maxRest > 0 ? maxRest : 12)
        }
        const currentMonth = (this.year * 100) + (idx + 1)
        return baseDisableLogic || currentMonth < infimum || currentMonth > suprimum
      }
      return baseDisableLogic
    },
    selectedStyle(idx) {
      if (!this.selectedStart) return ''
      const leftSelected = idx + 1 === this.selectedStart.month && this.year === this.selectedStart.year
      const thumbColor = 'bg-[#FF6A3A] hover:bg-[#F95031] text-white hover:text-white active:brightness-100'
      if (!this.selectedEnd) {
        if (!this.hoverEnd) return ({ [`${thumbColor} rounded-[4px]`]: leftSelected })
        let start = (this.selectedStart.year * 100) + this.selectedStart.month
        let end = this.hoverEnd
        if (start > end) {
          end = start
          start = this.hoverEnd
        }
        const infimum = (this.year * 100) + (idx + 1) > start
        const suprimum = (this.year * 100) + (idx + 1) < end
        return ({
          [`${thumbColor} rounded-[4px]`]: leftSelected,
          'bg-[#FFECE9]': infimum && suprimum && !this.disabledMonth(idx),
        })
      }
      const rightSelected = idx + 1 === this.selectedEnd.month && this.year === this.selectedEnd.year
      const infimum = (this.year * 100) + (idx + 1) > (this.selectedStart.year * 100) + this.selectedStart.month
      const suprimum = (this.year * 100) + (idx + 1) < (this.selectedEnd.year * 100) + this.selectedEnd.month
      return ({
        'bg-[#FF6A3A] hover:bg-[#F95031] text-white hover:text-white active:brightness-100': rightSelected || leftSelected,
        'rounded-l-[4px] rounded-r-0': leftSelected,
        'rounded-r-[4px] rounded-l-0': rightSelected,
        'bg-[#FFECE9]': infimum && suprimum,
      })
    },
    hoverStyle(idx) {
      return this.selectedStart && ((this.year * 100) + (idx + 1)) !== ((this.selectedStart.year * 100) + this.selectedStart.month) && !this.selectedEnd
    },
    clearModel() {
      this.selectedStart = null
      this.selectedEnd = null
      this.$emit('update:model', null)
    },
  },
}
</script>
